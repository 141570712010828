<template>
    <div class="catalog">
        <div class="catalog-title">
            <h2>ספק: {{selected_supplier_name}} - ({{selected_supplier}})</h2>
        </div>
        <div class="catalog-content">
            
        </div>
        <div class="catalog-pagination">

        </div>
    </div>
</template>

<script>
import {selected_supplier,selected_supplier_name} from '../client/store'
export default {
    setup () {
        

        return {
            selected_supplier,
            selected_supplier_name

        }
    }
}
</script>

<style  scoped>
    .catalog{
        width: 100%;
        height: 100%;
    }
    .catalog-title{
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .catalog-content{
        width: 100%;
        height: calc(100% - 100px);
        background: green;
    }
    .catalog-pagination{
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>